/** @jsx jsx */
import { jsx, Heading, Button } from "theme-ui"
import HomeLayout from "../components/home-layout"
import { SEO } from "gatsby-theme-swhwsys-core"
import { navigate } from "gatsby"

const Level3Services = () => (
	<HomeLayout>
		<SEO title="Level 3 Services" />

    <Heading as="h1"
      sx={{
        // textAlign: "center",
        mb: 2,
      }}>
      Level 3 Services
    </Heading>

    <Heading as="h2"
      sx={{
        // textAlign: "center",
        mb: 2,
      }}>
      Create Proposed IoT Solution Architecture
    </Heading>

		<ul>
			<li>Collaborate with stakeholders, leadership, Information Technology, and Engineering</li>
			<li>Explore IoT Solution implementation options</li>
      <ul>
        <li>Document IP Ownership</li>
        <li>Development Outsource/In-House or a combination</li>
        <li>Criteria for transitioning from development to ongoing operations</li>
      </ul>
			<li>Deliverable</li>
      <ul>
        <li>Initial IoT Solution Architecture documentation</li>
        <li>RFP to solicit solution implementations</li>
      </ul>
		</ul>

		<div sx={{ display: "grid", placeItems: "center" }}>
        <Button
          onClick={() => navigate(-1)}
          sx={{
            backgroundImage: (theme) =>
              `radial-gradient( circle farthest-corner at 10% 20%,  ${theme.colors.accent} 0%, ${theme.colors.highlight} 90% )`,
            color: "inherit",
            fontWeight: "bold",
            boxShadow: "default",
            transition: "all 0.3s ease",
            ":hover, :focus, :active": {
              boxShadow: "lg",
              backgroundImage: (theme) =>
                `radial-gradient( circle farthest-corner at 10% 20%,  ${theme.colors.highlight} 0%, ${theme.colors.accent} 90% )`,
            },
          }}
        >
          Back
        </Button>
      </div>

	</HomeLayout>
);

export default Level3Services;
